
import * as __star__ from '@clayui/modal';

const {
default: __default__,
ClayModalProvider,
Context,
useModal,
__esModule
} = __star__;

export {
ClayModalProvider,
Context,
useModal,
__esModule
};
export default __default__;
